html, body, #app, #app>div {
  height: 100%;
  width: 100%;
  overflow-y: hidden
}

body > #root > div {
  height: 100vh;
  width: 100vw
}

#root {
  height: 100vh;
  width: 100vw
}

.App {
  width: 100vw;
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i {
  vertical-align: middle!important; /*Ant icon vertical position fix*/
}

.form-control {
  font-size: 0.7rem;
  padding-top: 0.175rem;
  padding-right: 0.55rem;
  padding-bottom: 0.175rem;
  padding-left: 0.55rem;
}

.grid_margin {
  padding-left: 0rem !important;
  padding-right: 0rem !important; 
  margin-top: 3px !important;
  margin-bottom: 0px;
  margin-left: 3% !important; 
  margin-right: 3% !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  flex-grow: 1;
}

.header_margin {
  padding-left: 0rem !important;
  padding-right: 0rem !important; 
  margin-top: 20px !important;
  margin-bottom: 0px;
  margin-left: 3% !important; 
  margin-right: 3% !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  
}

.tab_margin {
  padding-left: 0rem !important;
  padding-right: 0rem !important; 
  margin-top: 3px !important;
  margin-bottom: 0px;
  margin-left: 3% !important; 
  margin-right: 3% !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  overflow: visible !important;
}

.card {
  box-shadow: 0 9px 10px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
  border-radius:  4px;
  background-color: white;
  padding: 10px !important;
}

.full_width {
  width: 100%;
}

.round_tag_ok {
 
  border-radius: 50px;
  background-color: #007BFF;
  color: white;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}

.round_tag_danger {
  padding: 3px;
  border-radius: 50px;
  background-color: #FF4D4F;
  color: white;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}

.round_tag_arrived {
  padding: 3px;
  border-radius: 50px;
  background-color: #696969;
  color: white;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}

.round_tag_progress {
  padding: 3px;
  border-radius: 50px;
  background-color: #52C41A;
  color: white;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;
}

.def_page {
  display: flex;
  flex-direction: column;
  
  overflow: auto;
 
}

.ant-input-number {
  width: 100% !important;
}

form .ant-input-number-handler-wrap {
 visibility: hidden;
}

.main_header{
  margin-left: 3% !important; 
  margin-top: 20px;
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 20px !important;
  font-size: 17px !important;
  
}

.loader-page {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: 0 0 0 0;
  border-radius:  0px;
  padding: 0px !important;
}

.ant-descriptions-row > td {
  background-color: white
}

.ant-tabs {
  overflow: visible !important;
}

/* .ant-card-body {
  padding: 0px !important;
} */

.react-bootstrap-table table { table-layout: auto !important; }

td {
  white-space: nowrap;
  text-overflow: ellipsis;
}